<template>
    <v-form>
      <div>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
          v-html="summaryError"
        ></v-alert>
        
        <v-file-input
            v-model="$v.form.file.$model"
            :error-messages="getErrors('form.file')"
            show-size
            :accept="`.xlsx`"
            label="Файл"
        ></v-file-input>
      </div> 
      
      <v-divider class="my-4" />

      <v-btn dark :color="$const.color.primary" :loading="waiting" @click.prevent.stop="upload">
          Загрузить
      </v-btn>
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'

export default {
    name: 'UserUploadForm',
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            form: {
                file: null
            },
            waiting: false
        };
    },
    validations() {
        return {
            form: {
                file: {
                  required: function(value) {
                    return !_.isNil(value);
                  }
                }
            }
        }
    },
    methods: {
        async upload() {
            if (this.validate()) {
                this.waiting = true;
                this.serverErrors = null;

                let form = new FormData();
                _.forOwn(this.form, (v,k) => {
                    form.append(k, v);
                })

                const { success, error } = await this.$store.dispatch('user/upload', form);

                if(!success)
                    this.serverErrors = error;
                else
                    this.$emit('back', 1);

                this.waiting = false;
                return ;
            }
        }
    }
}
</script>
<style scoped lang="scss">

</style>